import React from "react"

const toggleDarkMode = () => {
  document.body.classList.toggle("dark")
}

const Footer = () => {
  return (
    <footer className="o-footer" style={{ marginTop: 35 }}>
      <div className="l-container o-footer__container">
        <div className="m-social-links ">
          <strong className="a-type-md m-social-links__label">
            Follow Viral Patel on:
          </strong>
          <ul className="m-social-links__list">
            <li className="m-social-links__item">
              <button
                className="a-button a-button--secondary m-nav__menu-button a-button--square"
                onClick={toggleDarkMode}
              >
                {" "}
                <span role="img" aria-label="toggle">
                  🔦
                </span>
              </button>
            </li>
            <li className="m-social-links__item">
              <a
                className="a-button a-button--secondary a-button--square m-social-links__button"
                href="https://github.com/viral810"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  className="a-icon a-icon--fill a-icon--github a-button__icon"
                  viewBox="0 0 24 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>GitHub icon</title>
                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                </svg>
              </a>
            </li>

            <li className="m-social-links__item">
              <a
                className="a-button a-button--secondary a-button--square m-social-links__button"
                href="https://www.twitter.com/thedecodedcoder/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  role="img"
                  className="a-icon a-icon--fill a-icon--github a-button__icon"
                  viewBox="0 0 24 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>

                  <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                </svg>
              </a>
            </li>

            <li className="m-social-links__item">
              <a
                className="a-button a-button--secondary a-button--square m-social-links__button"
                href="https://www.instagram.com/thedecodedcoder/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="a-icon a-icon--fill a-icon--instagram a-button__icon"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Instagram</title>

                  <path d="M8 0c2.173 0 2.445.009 3.298.048.852.039 1.433.174 1.942.372.526.204.972.478 1.417.923.445.445.718.891.923 1.417.198.509.333 1.09.372 1.942.039.853.048 1.126.048 3.298 0 2.173-.009 2.445-.048 3.298-.039.852-.174 1.433-.372 1.942-.204.526-.478.972-.923 1.417-.445.445-.891.718-1.417.923-.509.198-1.09.333-1.942.372-.853.039-1.126.048-3.298.048-2.173 0-2.445-.009-3.298-.048-.852-.039-1.433-.174-1.942-.372-.526-.204-.972-.478-1.417-.923-.445-.445-.718-.891-.923-1.417-.198-.509-.333-1.09-.372-1.942-.039-.853-.048-1.126-.048-3.298 0-2.173.009-2.445.048-3.298.039-.852.174-1.433.372-1.942.204-.526.478-.972.923-1.417.445-.445.891-.718 1.417-.923.509-.198 1.09-.333 1.942-.372.853-.039 1.126-.048 3.298-.048zm0 1.441c-2.136 0-2.389.008-3.233.047-.78.036-1.204.166-1.485.275-.373.145-.64.318-.92.598-.28.28-.453.546-.598.92-.11.282-.24.705-.275 1.485-.038.844-.047 1.097-.047 3.233s.008 2.389.047 3.233c.036.78.166 1.204.275 1.485.145.373.319.64.598.92.28.28.546.453.92.598.282.11.705.24 1.485.275.843.038 1.096.047 3.233.047 2.136 0 2.389-.008 3.233-.047.78-.036 1.204-.166 1.485-.275.373-.145.64-.318.92-.598.28-.28.453-.546.598-.92.11-.282.24-.705.275-1.485.038-.844.047-1.097.047-3.233s-.008-2.389-.047-3.233c-.036-.78-.166-1.204-.275-1.485-.145-.373-.318-.64-.598-.92-.28-.28-.546-.453-.92-.598-.282-.11-.705-.24-1.485-.275-.844-.038-1.097-.047-3.233-.047zm0 2.45c2.269 0 4.108 1.839 4.108 4.108 0 2.269-1.839 4.108-4.108 4.108-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.839-4.108 4.108-4.108zm0 6.775c1.473 0 2.667-1.194 2.667-2.667 0-1.473-1.194-2.667-2.667-2.667-1.473 0-2.667 1.194-2.667 2.667 0 1.473 1.194 2.667 2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z" />
                </svg>
              </a>
            </li>

            {/* <li className="m-social-links__item">
              <a
                className="a-button a-button--secondary a-button--square m-social-links__button"
                href="https://www.linkedin.com/in/viralpatel810/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="a-icon a-icon--fill a-icon--linkedin a-button__icon"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn</title>

                  <path d="M16 15.545h-3.536v-5.113c0-1.338-.553-2.252-1.77-2.252-.93 0-1.448.617-1.689 1.211-.09.213-.076.51-.076.808v5.347h-3.503s.045-9.058 0-9.881h3.503v1.551c.207-.678 1.326-1.646 3.113-1.646 2.216 0 3.958 1.421 3.958 4.482v5.494zm-14.117-11.117h-.023c-1.129 0-1.861-.755-1.861-1.713 0-.976.754-1.716 1.905-1.716s1.858.738 1.881 1.713c0 .957-.73 1.716-1.902 1.716zm-1.48 1.236h3.119v9.881h-3.119v-9.881z" />
                </svg>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
